const TRANSLATIONS_EN = {
    que_es: 'What is it?',

    Inici: 'Home',

    Història: 'History',

    Protagonistes: 'Protagonists',

    festa: 'Celebrating the senses and sensations',

    que_es_1:
        'The Patum is the most important festival in Berga, with more than 600 years of history. It is celebrated once a year during the feast of Corpus Christi between the end of May and the end of June, occurring on variable dates because of its Easter cycle festivities. Dancing and fireworks are its most characteristic features.',

    que_es_2:
        'Its main events take place from Wednesday to Sunday of the week of Corpus Christi in the squares and streets of the town of Berga. The processions take place on Wednesday and Saturday and the Patum de Lluïment (ceremonial Patum at noon) and full Patum (at night) on Thursday and Sunday in Plaça de Sant Pere. Troupes are in charge of the performances or dances of each of the figures during the Patum’s festivities.',

    que_es_3:
        'Parallel events are also held in various parts of the town, such as family activities, youth concerts or exhibitions.',

    vides_de_patum: 'Patum lives',

    patum_infantil: "Children's Patum",

    Actualitat: 'Present',

    Notícies: 'News',

    Agenda: 'Agenda',

    Premsa: 'Press',

    'Totes les notícies': 'All news',

    'Dimecres de Corpus': 'Wednesday before Corpus Christi',

    'Passacarrers amb salts de Patum i Tirabols':
        'Procession with Patum and Tirabols performances',

    'Dia de Corpus (dijous)': 'Feast of Corpus Christi (Thursday)',

    'Patum de Lluïment i Patum completa':
        'Patum de Lluïment (ceremonial Patum) and the full Patum',

    'Divendres de Corpus': 'Friday after Corpus Christi',

    'Dissabte de Corpus': 'Saturday after Corpus Christi',

    'Diumenge de Corpus': 'Sunday after Corpus Christi',

    'Saber-ne més': 'More',

    diumenge_ascencio: 'Sunday after Ascension Day',

    diumenge_ascencio_1:
        'The Ascension Ple is held and the Tabal is brought out to announce the festivities (17 days before the Wednesday during Corpus Christi week)',

    patum_pietat: 'Patum in the Pietat neighbourhood',

    patum_pietat_1:
        'Twelve days before the Wednesday during Corpus Christi week',

    quatre_fuets: 'Quatre fuets',

    quatre_fuets_1:
        'Traditional rehearsal to try out the fireworks (fuets) that will be used during La Patum (Sunday before Corpus Christi week)',

    patum_llar: 'Patum de la Llar',

    patum_llar_1:
        'Patum that is held in the Llar Santa Maria de Queralt special education school (Tuesday of Corpus Christi week)',

    que_es_4:
        'During La Patum week, the processions will make their way along the streets of Berga (see the plan issued each year) and the performances are held in Plaça de Sant Pere.',

    'Passacarrers migdia dimecres': 'Midday parade wednesday',

    dimecres_1:
        'The procession at midday on Wednesday follows an itinerary through the main streets of the old quarter of Berga. The Tabaler (drummer) leads the way, while behind him two pairs of Gegants (giants) dance to the strains of the music played by a band. This event is suitable for children and youngsters and is the only Patum procession that does not involve fireworks.',

    dimecres_2:
        'The evening procession on Wednesday follows an itinerary through the main streets in the old quarter of Berga and stops at 21 spots in honour of the authorities and dignitaries of the town. The Tabal (drum) leads the way and every time the procession halts, there is a salt (dance round) in front of the place chosen by each of the town councillors. Fireworks, in the form of the Maces (maces) and Guites (mule dragons), and dancing, with the Maces and Gegants, are a constant feature of the salts. The troupes gradually dance their way along the route between each salt to the strains of the music. ',

    dimecres_3:
        'The procession ends with Tirabols (final dances) in Plaça de Sant Joan, where everyone dances around the square in circles as the music plays. People later make their way up Carrer Major to the sound of the popular melody Ella s’ho pensa, which is constantly played only on this street alone. The Tirabols are repeated on arrival in Plaça de Sant Pere.',

    dimecres_4:
        'The only troupes that participate in the processions are the Tabal, Maces, two Guites and the old Gegants couple.',

    'Patum de lluïment dijous i diumenge al migdia':
        'Wednesday and Sunday Patum de Lluïment (ceremonial Patum)',

    dijous_1:
        'The Patum de Lluïment (ceremonial Patum) takes place at midday. This is a peaceful and relatively restrained gala Patum as far as revelries are concerned. All the troupes, except the Plens (fire demons, who only come out at night), are summoned and each performs its dance once, one after the other. When this round of dances is over and all the troupes have completed their performance, the Tirabols (final dances) begin, in which the two Guites (mule dragons) and the two pairs of Gegants (giants) share the square while everyone circles around them as the music plays.',

    "Ordre d'aparició de les comparses": 'Order of appearance of the troupes',

    'Patum completa dijous i diumenge vespre':
        'Wednesday and Sunday evening full Patum',

    dijous_2:
        'The full Patum is the most popular part of the festivities and draws the largest crowd. The square fills with people while the troupes perform their dances in its centre. They enter the square one by one and dance to the beat of the Tabal (drum) or the music while everyone leaps about around them. The round of every troupe dance is repeated four times, with the exception of the Plens, who only perform at the end of the second and fourth round. Each round is known as a salt and every time the Plens dance, it is termed the salt de Plens (fire demon dance). Consequently, a full Patum consists of two salts, one salt de Plens, two salts and one final salt de Plens. In other words, the same structure is repeated.',

    dijous_3:
        'To bring the full Patum to an end, the Tirabols are danced, the moment when the two Guites and the two pairs of Gegants share the square while everyone circles around them to the strains of live music.',

    'Escola de Patumaires': 'Patumaires school',

    'Visita el Web': 'Visit the website',

    divendres_1:
        'Although this activity actually begins much earlier, with daily rehearsals over a two-week period, the Patum Infantil (children’s Patum) is held on Corpus Christi Friday. Berga’s children perform their own Patum in Plaça de Sant Pere with figures especially adapted for them.',

    Comparses: 'Troupes and figures',

    Música: 'Music',
    'La Música': ' The Music',

    'Administradors/es': 'Administrators',

    Patumaires: 'Patumaires',

    'Totes les notícices': 'All news',

    'Tots els esdeveniments': 'All events',

    Orígens: 'Origins',

    historia_1:
        'More than 600 years of history: from medieval play to Oral and Intangible Cultural Heritage of Humanity ',

    historia_2:
        'The origins of the Patum can be traced back to the old interludes that paraded in the Corpus Christi processions and were intended to educate, moralise and teach people so that they would have a deeper understanding of the Holy Scriptures. Over time, however, these interludes took on a character of their own and became popular, more so for their playful rather than instructive nature.\n\nThey gradually lost their original meaning and only the most festive parts remained of these performances. The revelry of the processional march increased in intensity, especially before the procession commenced and also once it was over.\n\nThis ultimately gave way to festive shows that inevitably formed the Bulla (commotion) or Bullícia del Santíssim Sagrament (frenzy of the Blessed Sacrament), which was in fact the former name of the festival before it was replaced by the Patum at the turn of the 19th century.\n\nThis phenomenon, which took place in several towns and cities in Catalonia, became lost as a result of the prohibitions passed by civil, ecclesiastical and royal powers, which meant that few of these expressions survived the Council of Trent (1563), and the death knell was even sounded for all the interludes by the restrictive provisions of the 18th century.\n\nOf all the former Bullícies of the Blessed Sacrament that took place in Catalonia, only the town of Berga has managed to maintain and preserve one throughout the centuries. It is known as the Patum.',

    'Cronologia sencera': 'Entire chronology',

    unesco_titol:
        'Masterpiece of the Oral and Intangible Cultural Heritage of Humanity',

    unesco: 'An international jury convened at UNESCO headquarters in Paris on 25 November 2005 publicly announced the list of forms of cultural expression that became Masterpieces of the Oral and Intangible Cultural Heritage of Humanity. Among those chosen was the Patum of Berga, because of its uniqueness and artistic and historical value, the first popular event to obtain this recognition in Catalonia and second in all of Spain after the Mystery Play of Elche.\n\n Obtaining this recognition is another reason why the Patum must maintain the essence of its festivities, given that UNESCO has become a direct protector of this form of cultural expression.\n\n The recognition of the Patum as a Masterpiece of the Oral and Intangible Cultural Heritage of Humanity is a collective merit, involving all the people who have perpetuated the most lived of our traditions. In addition to all those people who love the Patum and are proud to continue celebrating it year after year for Corpus Christi.',

    titol_tabal:
        'The Tabal (drum). The town crier of the festival. The sound of its beat (pa-tum, pa-tum) has given this festival its name since the late 18th century',

    text_tabal:
        'The Tabal is the only element of the Patum that participates in each and every one of the festivities. Its presence is constant and in some ways it can be said to preside over and direct them.\n\nDocumented since 1621, and reconstructed in 1726, it was the only instrument of the Patum that marked the changing beat of the troupes until the mid-17th century. The Tabal, therefore, did not have any specific symbolism within the performances, but was in charge of directing and coordinating the movements of the various interludes and marked the beat of each of the dances.\n\nThe prominence of the Tabal was notably displaced at the end of the 19th century, when musical pieces began to be introduced into the festival.\n\nNonetheless, the Tabal and the Tabaler (drummer) continue to play an essential role in the Patum. On Ascension Sunday, the Tabal is in charge of announcing the imminent arrival of the Patum throughout Berga. At noon on the eve of Corpus Christi, it precedes the pass of the Gegants (giants) and it also leads the processions, or passes, in the evening on the same day (and the following Saturday). It currently presides over the performances of the Patum in Plaça de Sant Pere from a privileged position and in earlier times used to lead the rest of the Patum’s troupes in the Corpus Christi processions.\n\nIf at some point on the Sunday of the Ascension you do not hear the beating of the Tabal announcing the municipal agreement, then you know that there will be no Patum during that year.',

    titol_turcs:
        'The Turcs (Turks) and Cavallets (little horses): the dance of the Turcs and Cavallets is thought to be one of the oldest in the Patum',

    text_turcs:
        'Although they may date back to ancient pagan animal husbandry rituals, they now represent the secular struggle between the Cross and the Crescent.\n\nFour Christian knights and four Turkish foot soldiers simulate a battle in which the former are always victorious.\n\nThe first known reference can be found in 1621, although the current figures were constructed in 1890. The design of these Berga horses was simple, naive and basic until 1889: the horse’s rump was simulated by a cloth-covered hoop that concealed the rider’s legs, with a tiny wooden head up front and a doll sitting on its rump. The new horses, commissioned from La Perfección in Barcelona, cost 200 pesetas (1.20 euros). Interestingly enough, the costumes of the Turcs and Cavallets, purchased from the same company, were more expensive than the horses themselves: 250 pesetas (1.50 euros).\n\nThe Turcs and Cavallets stopped moving to the beat of the Tabal (drum) and in 1890 began to dance to the strains of a musical piece composed by Joaquim Serra i Farriols, who was known as ‘Quimserra’.',

    titol_maces:
        'The Maces (maces): the eternal struggle between Good and Evil',

    text_maces:
        'This centuries-old performance, documented since 1621, symbolises the eternal struggle between Good and Evil as portrayed by a childlike battle scene between angels and demons.\n\nThe mace consists of a handle topped by a round box that contains stones so that it makes a characteristic sound when it is shaken. They are decorated with evil faces and a gerb (fuet) on top. The Maces are carried by characters dressed as demons, with their faces covered by a mask.\n\nThey move through the square as they dance until the gerb explodes. This is when the demons fall to the ground and the angels kill them with their spears and swords.\n\nThe Maces were the last troupe to incorporate music, composed by Joan Trullàs in 1963. This music is only performed in the salts (dance rounds) at midday, while in the evening the Maces continue to move as always to the beat of the Tabal (drum).\n\nIn olden days, the Maces and Plens (fire demons) were part of the same performance representation, which was that of the demons.',

    titol_angels:
        'The Àngels (angels): representatives of Good struggling against Evil',

    text_angels:
        'The Àngels are always linked to the Maces (maces) because they are part of the same performance representation. Two representatives of the forces of Good can be found in this representation today: Saint Michael and another angel accompanying him.\n\nThe Àngels have been documented since 1621, when only Saint Michael appeared. His assistant finally joined the representation in the first half of the 19th century.\n\nThe archangel Saint Michael wore a mask until the first decade of the 20th century and it is currently kept in the Berga Regional Museum.',

    titol_guites:
        'The Guites (mule dragons): one of the oldest elements of the Patum',

    text_guites:
        'In the past, they were also known as Mulassa, Mulafera and Mulaguita and they are one of the oldest and most unique elements of the celebration. Together with the Plens (fire demon), the Guites are unique characters in the world, given that their archaic mule form is part of a unique element in the festive bestiary of Spain and the world.\n\nTraditionally, there was only one dragon, the Guita Grossa (big mule dragon), which has been documented since 1621.\n\nThe Guites make up one part of the Patum that has changed the least since its origins and retains more basic forms. Although its form has not changed, what has changed is the number of people who carry it, known as the guitaires. Until the beginning of the 19th century, only two people were in charge of carrying the Guita. This number gradually increased and there are currently 28 guitaires accompanying the Guita Grossa and 16 accompanying the Guita Xica (small mule dragon).\n\nThe most important change that this troupe has undergone throughout its history has been the introduction of the Guita Xica, popularly referred to as ‘Boja’ (crazy) in 1890, during the process of consolidating the Patum. It was born in Carrer de les Canals (now Marcel·lí Buxadé) in one of the many neighbourhood patums that took place at the time. After requesting the relevant permission from Berga Town Council, it went out to the square by surprise in that year. The feeling it caused among the people of Berga was good enough for it to be accepted and it became part of the Patum together with the Guita Grossa.\n\nThe Guites are the only Patum troupe that continues to dance exclusively to the beat of the Tabal (drum). It is thought that its salt (dance round) is the same as in its origins, despite the fact that more than 400 years have passed since the Guita was first mentioned.',

    titol_aliga:
        'The Àliga (eagle). Performing the people of Berga’s most beloved and majestic dance',

    text_aliga:
        'The Àliga is undoubtedly the element of Catalonia’s festive bestiary that differs most from the rest. It is the most stately, aristocratic and distinguished figure. The Àliga enjoyed a number of privileges in the past, such as dancing in the church presbytery or leading the Corpus Christi processional custody.\n\nThe Patum’s Àliga emerged later than other elements of the festival and its history still remains a great mystery today.\n\nThe Berga Town Council decided to incorporate an eagle into the solemnities of the Corpus Christi procession in 1756 and commissioned the carpenter Ramon Roca to build one. This could be the same Àliga, suitably restored and modified, that currently dances in today’s Patum.\n\nIts dance is the most choreographed of all the performance pieces and its music the most distinguished and monumental. The extraordinary score bears many resemblances to a typical Renaissance dance that may have originated in the 16th century, although its melodic line apparently derives from a Gregorian hymn. The melody of the dance of the Àliga in the Patum of Berga has been defined as one of the three ‘greatest’ pieces of Catalan popular music.',

    'titol_nans-vells':
        'The Nans Vells (old dwarfs). Four characters with tricorn hats and castanets',

    'text_nans-vells':
        'The Gegants (giants) and Nans (dwarfs) are the festive elements that have enjoyed the most popularity over the centuries. There are few towns in Catalonia today that do not have one of these groups and the Patum of Berga is no exception.\n\nThe Nans Vells and Nans Nous (new dwarfs) were introduced quite recently to the Patum. The Nans Vells date back to 1853 and were a gift to the town of Berga by Ferran Moragues i Ubach, the first member of parliament from the district of Berga, at a time when these characters were currently in vogue.\n\nThere are four male figures with tricorn hats and long wigs that dance and play castanets to the strains of popular melodies, the same tunes that the Gegants dance. The fact that they do not have their own music is perhaps seemingly explained by their surprise appearance in Berga, which forced them to make use of the music that was already being played to perform their dance',

    titol_gegants: 'The Gegants (giants). Vanquished former Moorish warlords',

    text_gegants:
        'The first Gegant of the festival has been documented back to 1622 and the first couple to 1695. Popular tradition, especially since Romanticism, has associated them to vanquished former Moorish warlords.\n\nThere are currently two couples dancing together in the Patum: the Vells (old giants), first appearing in 1866 and replacing older giants, and the Nous (new giants), constructed in 1891.\n\nTheir dances are mostly adaptations of popular Catalan melodies, although the Berga composers Jaume Sala and Monsignor Marià Miró also wrote dances for these giants.',

    'titol_nans-nous':
        'The Nans Nous (new dwarfs). Four fidgety, deformed figures',

    'text_nans-nous':
        'These figures were introduced in 1890 to replace the Nans Vells (old dwarfs), which were in very poor condition. Luckily, however, the latter were restored and have continued to participate in the Patum until today.\n\nThe Nans Nous are two couples, one young and the other old, who dance to the strains of a light, playful melody composed at the end of the 19th century by Joaquim Serra i Farriols, known as ‘Quimserra’, which has become the most popular and disseminated music from the Patum. It appears in the repertoire of many Catalan groups under the title of ‘La Patum de Berga’. In earlier times, they had danced to various tunes that were in vogue at the time, such as rigaudons and those from the Americas.',

    titol_plens: 'The Plens (fire demons). The climax of the festival ',

    text_plens:
        'The most spectacular show of the Patum, its culminating moment, the climax of the party.\n\nThe Plens have been documented back to 1621 and are believed to owe their name to the fact that they are full of fire. They are, therefore, demons plens de foc (full of fire).\n\nThe Plens moved purely to the beat of the Tabal (drum) until the end of the 19th century, but since then they have danced about to the wonderful and exciting music composed by ‘Quimserra’. When the streetlights go out and the music begins, the square becomes an inferno filled with fire from the scores of gerbs exploding at once. A total of 100 Plens, each with nine sets of gerbs, dance and leap about. Each Plen has a companion leading them through the square as they dance and leap about.\n\nIt is worth noting that the number of Plens has grown over the centuries. Two demons were documented in the 17th century, while four, eight or sometimes even 12 were dancing and leaping about before the Civil War. To celebrate the special Patum held for the Canonical Coronation of the Virgin of Queralt in 1916, a performance of 16 dancing Plens took place and people believed that there was too much fire in the square and it all posed a danger. This figure rose to 40 later, a number that has now increased to the 100 dancing and leaping about today.',

    'El component fonamental': 'A key element',

    musica_1:
        'Music is a key element of the festival and it has been especially so since the late 19th century, when various melodies were incorporated into the Patum. The festival today cannot be conceived without the music performed by the Cobla Pirineu, Cobla Ciutat de Berga and Berga’s Municipal Music School Band.\n\nMost of the music in the Patum is simple, traditional and in some ways uniquely wonderful music. One of the most important musicians of the Patum is Joaquim Serra i Farriols, ‘Quimserra’ (Berga, 1834-1906), composer of the music for the Turcs (Turks) and Cavallets (little horses), Plens (fire demons) and Nans Nous (new dwarfs). He was the one who radically changed the musical landscape of the Patum to make it definitively modern.\n\nMention should also be made of Ricard Cuadra i Camprubí (Berga 1951-1997), who organised the Forgotten Music of the Patum Concert in 1993 in order to recover old pieces that were no longer being performed. The success achieved with the unintentional reintroduction of works that had been forgotten for decades meant that this concert was held again in 1995 and 1997, helping to recover even more new melodies. His untimely death in 1997 led to the establishment of the Ricard Cuadra Memorial, which continues the work he left unfinished with an annual concert on Holy Trinity Saturday.',

    administradors_1:
        'The people traditionally in charge of raising money to pay for the festival',

    administradors_2:
        'The Administrators are not really a Patum troupe, but they do play an important role.\n\nThe first documented reference to these figures dates back to 1619. Traditionally, their task was to raise funds to pay for the festivities of the Octave of Corpus Christi.\n\nThere are four pairs of Administrators that represent the former Four Quarters in which the town was divided: Feixines or Capdamunt de la Vila (Fonolls), Sant Pere (Esclafidors Rebentats), Centre or Carrer Major (Esclafidors Embotits) and Raval (Ravenisses). Today, they play a protocol role and preside over the festivities from the balcony of the Town Hall.',

    patum_infantil_1:
        'The Patum Infantil (children’s Patum) is celebrated on Corpus Christi Friday, although this activity for Berga’s youngsters begins much earlier. Rehearsals for the Patum Infantil last two weeks, and during this time the children of the town learn the various dances of the festivities, which is why it is known as a ‘escola de patumaires’, or school for Patum people.\n\nOn Corpus Christi Friday, the boys and girls of Berga perform their own Patum in Plaça de Sant Pere, which consists of the same elements as the one performed by adults, although smaller in size.\n\nThe Patum Infantil begins in the morning with a procession through the streets of Berga and performances of the Patum de Lluïment (ceremonial Patum) and full Patum at noon and in the evening, complete with all the troupes: Tabal (drum), Turcs (Turks) and Cavallets (little horses), Maces (maces) and Àngels (angels), Guites (mule dragons), Àliga (eagle), Nans Vells (old dwarfs), Gegants (giants), Nans Nous (new dwarfs), Plens (fire demons) and Tirabols (final dances).',

    'Per a més informació:': 'For more information:',

    patumaires_1:
        'Thousands upon thousands of patumaires, or Patum people, make the Patum. But each year, Berga Town Council distinguishes a number of individuals who deserve special recognition because of their involvement and collaboration in the festivities. These people are awarded the title of Patumaire or Patumaire of Honour.',
    'Títol de Patumaire': 'Title of Patumaire',
    "Patumaire d'honor": 'Patumaire of Honour',
    'Objecte i composició': 'Purpose and Composition',
    text_objecte:
        'The Patum Municipal Board is a body with sectoral involvement whose aim since 29 March 2017 has been to administer, conserve and coordinate the development of the Patum festival, declared a Masterpiece of the Oral and Intangible Cultural Heritage of Humanity by UNESCO. This aim also encompasses all the activities arising from and strictly related to the festival’s troupes, their performances and their members, ensuring the proper use of the name and image of the celebration, as well as everything related to its corporate, advertising and dissemination image and everything that, in general or specifically, includes the name Patum or refers to it. The Board was set up on 24 April 2001 as an autonomous municipal body.\n\nIt comprises representatives from the various municipal political groups in the Town Council, several colla (team) leaders, who are appointed to the position on a rotating basis, and representatives of the Patum collective. Its composition is renewed every two years and that corresponding to the two-year period of 2019-21 is the one detailed below: ',
    Normativa: 'Regulations',
    text_normativa:
        'As a body of sectoral involvement, the Patum Municipal Board has its own regulations that govern its purpose, composition and operation. ',
    'Acords de les sessions': 'Session Agreements',
    text_acords:
        'The Patum Municipal Board meets periodically throughout the year in sessions of an ordinary or extraordinary nature, in which various issues related to the party are discussed.',
    'Reglaments de règim intern de les comparses':
        'Internal regulations of the Troupes',
    text_reglaments:
        'Internal regulations have governed the troupes of the Patum since 2017 and these detail, among other things, the composition, operation and mechanisms available to the public to join or participate in the rehearsals or performances. These regulations have been ratified by the Patum Municipal Board. ',
    Formularis: 'Forms',
    'Formularis de sol·licitud': 'Application forms',
    text_formularis:
        'Berga Town Council and the Patum Municipal Board of Trustees promote the participation of citizens and entities in various aspects of the Patum: Patum poster competition, selection process of the Administrators, draw for accessing the balcony during the Patum festivities in the square or tender for bar stands during the youth concert, among others. ',
    glossari_comparsa:
        'Each of the troupes who put on a performance or dance for the figures involved in the festivities: Tabal (drum), Turcs (Turks) and Cavallets (little horses), Àngels (angels), Maces (maces), Guita Grossa (big mule dragon), Guita Xica (small mule dragon), Àliga (eagle), Nans Vells (old dwarfs), Gegants (giants), Nans Nous (new dwarfs) and Plens (fire demons).',
    glossari_passacarrers:
        'Processions featuring some of the Patum troupes around the streets and squares of Berga, with stops at fixed points along the way where salts (dance round) are performed. There are three processions and each follows different routes during Corpus Christi week: Wednesday at midday and in the evening, and Saturday in the evening.',
    glossari_salt:
        'This word is used to refer to the individual performances or dances by each troupe and also the rounds of performances or dances by the troupes. During the processions, the round consists of the Maces (maces), Guites (mule dragons) and Gegants (giants), whereas during the patums in the square, all the troupes take part in the round, beginning with the Turcs (Turks) and Cavallets (little horses) and ending with the Nans Nous (new dwarfs).',
    glossari_plens:
        'During a salt de Plens (fire demon dance), Plaça de Sant Pere fills with a hundred Plens (fire demons), their attendants and people wishing to participate. When the streetlights in the square go off and the music begins, the attendants use the sparklers they are holding to light the nine gerbs carried by each demon. A dance then begins in which the participants circle in an anticlockwise direction. In the event of an incident, the music stops and the streetlights go back on. The dance ends when all the fireworks have exploded.',
    glossari_fuet:
        'These are the gerbs used during the performance by the troupes associated with fire: Maces (maces), Guites (mule dragons) and Plens (fire demons). A fuet is made of two parts: one that is lit and shoots off sparks for more than a minute as it burns and another that eventually explodes when the gerb has completely burnt down.',
    glossari_tirabol:
        'The final dance that brings the processions and patums to an end in the square. During the Tirabol, the Gegants (giants) and Guites (mule dragons) dance at the same time to the beat of the Tabal (drum) and the music as everyone goes round in circles in the square.',
    glossari_barreja:
        'Alcoholic drinks historically associated with the Patum. Barreja contains anise and muscatel, while maumau is made of red vermouth and lemonade. Traditionally, they are drunk from a wineskin or porró glass pitcher.',
    Càrrec: 'Position',
    'Nom i cognoms del o la titular': 'Full name of holder',
    'Nom i cognoms del o la suplent': 'Full name of alternate',
    Glossari: 'GLOSSARY',
    Consells: 'RECOMMENDATIONS',
    'Vides de Patum': 'Patum People',
    vides: 'Thousands upon thousands of patumaires, or Patum people, make the Patum. But each year, Berga Town Council distinguishes a number of individuals who deserve special recognition because of their involvement and collaboration in the festivities. These people are awarded the title of Patumaire or Patumaire of Honour.',
    'Dossiers de premsa': 'Press Kit',
    Acreditacions: 'Accreditations',
    Contacte: 'Contact',
    'Correu electrònic': 'E-mail',
    Telèfon: 'Phone',
    'Disseny i programació': 'Design and programming',
};

export default TRANSLATIONS_EN;
