const TRANSLATIONS_CA = {
    que_es: 'Què és?',
    festa: 'La festa dels sentits i dels sentiments',
    que_es_1:
        'La Patum és la festa més important de Berga, amb més de 600 anys d’història. Se celebra durant la festivitat del Corpus, un cop l’any entre finals de maig i finals de juny, ja que com a festa del cicle de Pasqua és de calendari variable. El ball i el foc en són la part més característica.',
    que_es_2:
        'Els actes principals es duen a terme des del dimecres al diumenge de la setmana del Corpus per places i carrers de Berga. El dimecres i el dissabte hi ha els passacarrers, i el dijous i el diumenge les patums de Lluïment (migdia) i completes (nit) a la plaça de Sant Pere. Les comparses s’encarreguen de l’actuació o el ball de cadascuna de les figures durant la festa de La Patum.',
    que_es_3:
        'També es realitzen actes paral·lels en punts diversos de la ciutat, com són activitats familiars, concerts joves o exposicions.',
    vides_de_patum: 'Vides de Patum',
    patum_infantil: 'Patum Infantil',
    historia_1:
        'Més de 600 anys d’història: d’obra de teatre medieval a Patrimoni Oral i Immaterial de la Humanitat',
    historia_2:
        'L’origen de La Patum cal buscar-lo en els antics entremesos que desfilaven a les processons del Corpus Christi i que estaven destinats a educar, moralitzar i alliçonar el poble per tal que aprofundís en el coneixement de les Sagrades Escriptures. Amb el pas del temps, però, aquests entremesos anaren prenent entitat pròpia i s’anaren popularitzant, més per la seva vessant lúdica que pel seu caràcter alliçonador.\n\nDe mica en mica, anaren perdent el seu sentit original i, d’aquestes representacions, en restaren només les parts més festives. La gresca del seguici processional va anar pujant de to, sobretot abans de la sortida de la processó i també un cop finalitzada aquesta. \n\nAixò va acabar donant pas a unes mostres festives que acabaren conformant la Bulla o Bullícia del Santíssim Sagrament, nom antic de la festa que fou substituït, al tombant del segle XIX, pel de La Patum. \n\nAquest fenomen, que tingué lloc a diverses viles i ciutats de Catalunya, es va anar extingint com a conseqüència de les prohibicions promulgades pels poders civil, eclesiàstic i reial, de manera que poques manifestacions sobrevisqueren al Concili de Trento (1563), i fins i tot els entremesos foren tocats de mort per les restrictives disposicions del segle XVIII.\n\nD’entre totes les antigues Bullícies del Santíssim Sagrament que tenien lloc a Catalunya, únicament la ciutat de Berga ha estat capaç de mantenir i conservar-la a través dels segles. És La Patum.',
    unesco_titol: 'Obra Mestra del Patrimoni Oral i Immaterial de la Humanitat',
    unesco: 'El 25 de novembre de 2005, un jurat internacional reunit a la seu de la UNESCO a París feia pública la llista de formes d’expressió cultural que esdevenien Obres Mestres del Patrimoni Oral i Immaterial de la Humanitat. Entre les escollides hi figurava La Patum de Berga, per la seva singularitat i valor artístic i històric, primera manifestació popular que obtenia aquest reconeixement a Catalunya i segona en tot l’Estat espanyol després del Misteri d’Elx.\n\n L’obtenció d’aquest reconeixement suposa per a La Patum un element més per mantenir l’essència de la festa, ja que la mateixa UNESCO ha esdevingut una protectora directa d’aquesta forma d’expressió cultural. \n\nEl reconeixement de La Patum com a Obra Mestra del Patrimoni Oral i Immaterial de la Humanitat és un mèrit col·lectiu, de totes aquelles persones que han perpetuat la més viscuda de les nostres tradicions. I també ho és de totes aquelles persones que estimen La Patum i se senten orgulloses de continuar-la, any rere any, per Corpus.',
    diumenge_ascencio: "Diumenge de l'ascensió",
    diumenge_ascencio_1:
        "Ple de l'Ascensió i sortida del Tabal anunciant les festes (17 dies abans de dimecres de Corpus)",
    patum_pietat: 'Patum del barri de la Pietat',
    patum_pietat_1: '12 dies abans de dimecres de Corpus',
    quatre_fuets: 'Quatre fuets',
    quatre_fuets_1:
        'Tradicional prova dels fuets que s’utilitzaran durant La Patum (Diumenge previ a la setmana de Corpus)',
    patum_llar: 'Patum de la Llar',
    patum_llar_1:
        "Patum que es fa a l'escola d'educació especial Llar Santa Maria de Queralt (Dimarts de la setmana de Corpus)",
    que_es_4:
        'Durant la setmana de Patum les passades van pels carrers de Berga i les patums es duen a terme a la Plaça de Sant Pere.',
    dimecres_1:
        'El passacarrers o passada de dimecres al migdia recorre els principals carrers del barri vell de Berga. El Tabaler dirigeix la ruta i al seu darrere ballen les dues parelles de Gegants al so de la música de la banda. Aquest recorregut és seguit per infants i joves i és l’única passada de La Patum on no hi ha foc.',
    dimecres_2:
        'El passacarrers o passada de dimecres al vespre recorre els principals carrers del barri vell de Berga i s’atura en 21 punts en honor a les autoritats i representants del poble. El Tabal dirigeix la ruta i cada cop que s’atura es fa “un salt” davant del lloc escollit per cadascun dels regidors i regidores de l’Ajuntament. Cada salt consisteix en una encesa i ball de Maces, una de Guites i un ball de Gegants. Entre salt i salt, les comparses van avançant pel recorregut ballant al so de la música. ',
    dimecres_3:
        'La passada s’acaba amb Tirabols a la plaça de Sant Joan, on tothom fa voltes a la plaça al so de la música. Després es realitza la pujada pel carrer Major al so de la popular melodia ‘Ella s’ho pensa’, que s’interpreta únicament en aquest carrer i de forma ininterrompuda. En arribar a la plaça de Sant Pere també es fan Tirabols.',
    dimecres_4:
        'Les úniques comparses que participen al passacarrers són el Tabal, les Maces, les dues Guites i la parella vella de Gegants.',
    dijous_1:
        'Al migdia té lloc La Patum de Lluïment, és La Patum “de gala”, una Patum tranquil·la i que es viu de manera força mesurada pel que fa a la disbauxa. Totes les comparses excepte els Plens (només surten de nit) hi són convocades i surten a fer la seva dansa una sola vegada cadascuna i de manera consecutiva. Quan acaba aquesta ronda, i ja totes les comparses han fet la seva actuació, s’inicien els Tirabols on les dues Guites i les dues parelles de Gegants comparteixen la plaça mentre tothom volta al so de la música.',
    dijous_2:
        'La Patum completa és la part de la festa més popular i amb més afluència de públic. La plaça queda completament plena i al centre s’hi desenvolupen els balls de les comparses. Les comparses van sortint una per una a la plaça i fan els seus balls al so del Tabal o de la música mentre la gent salta al seu voltant. La ronda de totes les comparses es repeteix 4 vegades, a excepció dels Plens que només fan la seva actuació al final de la segona i quarta ronda de comparses. Cada ronda s’anomena “salt” i cada cop que ballen els Plens s’anomena “salt de Plens”. Per tant, en una Patum completa hi ha 2 salts, 1 salt de Plens, 2 salts i 1 darrer salt de Plens. Dues repeticions de la mateixa estructura.',
    dijous_3:
        'Per acabar La Patum completa es ballen els Tirabols, moment on les dues Guites i les dues parelles de Gegants comparteixen la plaça mentre tothom volta al so de la música en directe.',
    divendres_1:
        'Tot i que l’activitat comença molt abans amb els assajos diaris durant dues setmanes, La Patum Infantil se celebra el divendres de Corpus. La canalla de Berga representa la seva pròpia Patum a la plaça de Sant Pere amb les figures adaptades als infants.',
    titol_tabal:
        'És el pregoner de la festa. El so del seu repic: pa-tum, pa-tum dona nom, des de finals del segle XVIII, a la festa',
    text_tabal:
        'El Tabal és l’únic element de La Patum que participa en tots i cadascun dels actes de la festa. La seva presència és constant i, en certa manera, es pot dir que la presideix i dirigeix.\n\nDocumentat des del 1621, i reconstruït el 1726, fou l’únic instrument de La Patum al ritme del qual evolucionaven les comparses fins a mitjan segle XVII. El Tabal, doncs, no tenia cap simbolisme concret dins les representacions sinó que era l’encarregat de dirigir i coordinar les evolucions dels diferents entremesos i marcava el ritme de cadascuna de les danses.\n\nA la darreria del segle XIX, quan es van començar a introduir peces musicals a la festa, el protagonisme del Tabal va quedar notablement desplaçat.\n\nTot i això, Tabal i tabaler continuen sent un element imprescindible a La Patum. El diumenge de l’Ascensió, el Tabal és l’encarregat d’anunciar la imminent arribada de La Patum a tot Berga. Al migdia de la vigília de Corpus, precedeix la passada dels Gegants i, el mateix dia al vespre (i el dissabte següent), també encapçala els passacarrers o passades. Actualment presideix des d’un lloc privilegiat les representacions de La Patum a la plaça de Sant Pere i, antigament, anava al capdavant de la resta de comparses de La Patum en les processons de Corpus.\n\nI és que si alguna vegada el diumenge de l’Ascensió no se sentís el repic del Tabal anunciant l’acord municipal, aquell any no hi hauria Patum.',
    titol_turcs:
        'Es creu que el ball dels Turcs i Cavallets és un dels més antics de La Patum',
    text_turcs:
        'Tot i que poden derivar d’antigues representacions paganes de caràcter ramader, actualment representen la lluita secular entre la Creu i la Mitja Lluna.\n\nQuatre cavallers cristians i quatre turcs a peu simulen una batalla de la qual els primers resulten sempre vencedors.\n\nLa primera referència que en coneixem data del 1621, tot i que les figures actuals van ser construïdes el 1890. Fins el 1889, els cavallets berguedans tenien una forma simple, ingènua i rudimentària: la gropa del cavall era simulada per un cèrcol cobert de roba que tapava les cames del genet, un diminut cap de fusta al davant i una nina asseguda a la gropa. Els nous cavallets, encarregats a la casa La Perfección de Barcelona, costaren 200 pessetes (1’20 euros). Curiosament, els vestits dels Turcs i Cavallets, comprats a la mateixa casa, van resultar més cars que els mateixos cavallets, 250 pessetes (1’50 euros).\n\nFou l’any 1890 quan els Turcs i Cavallets deixaren d’evolucionar al so del Tabal i van passar a dansar als acords de la música composta per en Joaquim Serra i Farriols, el “Quimserra”.',
    titol_maces: 'L’eterna lluita entre el Bé i el Mal',
    text_maces:
        'Aquesta antiga escena, documentada des del 1621, simbolitza l’eterna lluita entre el Bé i el Mal, representada a través de l’escenificació ingènua d’una batalla entre àngels i dimonis.\n\nLa maça consisteix en un mànec rematat per una caixa rodona, que conté pedres que en ser sacsejades emeten un soroll característic. Estan decorades amb cares diabòliques i a sobre hi duen un fuet. Les Maces són portades per uns personatges vestits de dimonis i que porten la cara coberta amb una carota.\n\nDurant el seu ball, les Maces van evolucionant per la plaça fins que peta el fuet. És llavors quan els dimonis cauen a terra i els àngels els rematen amb la llança i l’espasa.\n\nLes Maces foren la darrera comparsa a la qual s’incorporà música, composta per Joan Trullàs l’any 1963. Aquesta música només s’interpreta als salts de Lluïment (al migdia). A la nit, les Maces continuen evolucionant, com sempre, al so del Tabal.\n\nAntigament, Maces i Plens havien format part d’un únic quadre escènic, el dels dimonis.',
    titol_angels: 'Els representants del Bé lluitant contra el Mal',
    text_angels:
        'Els Àngels els trobem sempre relacionats amb les Maces pel fet de formar part del mateix quadre escènic. Actualment prenen part en l’escena dos representants de les forces del Bé: Sant Miquel i un altre àngel que l’acompanya.\n\nEls Àngels es troben documentats des del 1621, quan només apareix Sant Miquel. El seu ajudant s’incorporà definitivament a la representació a la primera meitat del segle XIX.\n\nFins a la primera dècada del segle XX, l’arcàngel Sant Miquel lluïa una carota, avui conservada al Museu Comarcal de Berga.',
    titol_guites: 'Un dels elements més primitius de La Patum',
    text_guites:
        'Antigament també se l’havia anomenat Mulassa, Mulafera i Mulaguita. És un dels elements més antics i singulars de la celebració. És, juntament amb els Plens, un dels personatges únics al món ja que la seva forma arcaica de mulassa forma part d’un element únic dins el bestiari festiu de l’Estat espanyol i mundial.\n\nTradicionalment només n’hi havia una, la Guita Grossa, de la qual en tenim constància des del 1621.\n\nLes Guites són l’element de La Patum que menys ha canviat des dels orígens i que conserva unes formes més primitives. Tot i que no hagi canviat en la forma, sí que ho ha fet en el nombre de persones que la porten, els guitaires. Fins a inicis del segle XIX eren únicament dues persones les encarregades de portar la Guita. Aquest nombre es va anar incrementant i, actualment, els guitaires són 28 que acompanyen la Guita Grossa i 16 que acompanyen la Xica.\n\nEl canvi més important que ha patit aquesta comparsa al llarg de la seva història ha estat la introducció de la Guita Xica, popularment coneguda com a ‘Boja’, l’any 1890, en ple procés de potenciació de La Patum. Nasqué al carrer de les Canals (avui de Marcel·lí Buxadé), en una de les tantes patums de barri que es feien aquella època. Aquell any, després de demanar el corresponent permís a l’Ajuntament de Berga, va sortir a la plaça per sorpresa. La sensació que va causar entre els berguedans fou suficientment bona perquè s’acceptés i passés a formar part de La Patum, al costat de la Guita Grossa.\n\nLes Guites són l’única comparsa de La Patum que continuen saltant exclusivament al so del Tabal. Tot i haver passat més de 400 anys des de la primera notícia que tenim de la Guita, es creu que el salt es manté com en els seus orígens.',
    titol_aliga:
        "L'Àliga. Balla la dansa més majestuosa i apreciada pels berguedans i berguedanes",
    text_aliga:
        "L’Àliga és, sens dubte, l’element del bestiari festiu català que més es diferencia de la resta. És la figura més senyorial, aristocràtica i distingida. Antigament, l’Àliga gaudia d’un seguit de privilegis com el de ballar en el presbiteri de l’església o d’anar davant de la custòdia en les processons del Corpus.\n\nL’Àliga de La Patum té un naixement més tardà que el d’altres elements de la festa i la seva història representa, encara avui, un gran enigma.\n\nL’any 1756, l’Ajuntament de Berga va decidir incorporar una àliga a les solemnitats del Corpus i en va encarregar la construcció d'una al fuster Ramon Roca. Podria ser la mateixa, convenientment restaurada i modificada, la que balla actualment a La Patum.\n\nEl seu ball és el de major valor coreogràfic de tota la representació i la seva música la més distingida i monumental. Aquesta extraordinària partitura presenta moltes semblances amb una dansa típica del Renaixement que podria tenir el seu origen al segle XVI, per bé que la seva línia melòdica sembla derivar d’un himne gregorià. S’ha arribat a definir la melodia del ball de l’Àliga de La Patum de Berga com una de les tres peces més ‘genials’ de la música popular catalana.",
    'titol_nans-vells': 'Quatre figures amb barret de tres pics i castanyoles',
    'text_nans-vells':
        'Els Gegants i els Nans són els elements festius que han gaudit de més popularitat al llarg dels segles. Actualment, a Catalunya, són poques les poblacions que no disposen d’alguna d’aquestes comparses i La Patum de Berga no n’és una excepció.\n\nLa introducció dels Nans Vells i els Nans Nous a La Patum és força moderna. Els Nans Vells daten del 1853 i foren un regal a la vila de Berga de Ferran Moragues i Ubach, primer diputat a Corts del districte de Berga, en un moment en què aquests personatges eren moda a l’època.\n\nSón quatre figures masculines, amb barret de tres pics i llargues perruques, que ballen, tocant les castanyoles, als acords de melodies populars, les mateixes que dansen els Gegants. El fet de no disposar de música pròpia sembla que pot explicar-se, segurament, per la seva arribada per sorpresa a Berga, fet que va obligar a aprofitar les músiques que ja s’interpretaven per fer-los dansar.',
    titol_gegants: 'Els antics cabdills musulmans vençuts',
    text_gegants:
        'El primer Gegant de la festa el trobem documentat l’any 1622 i la primera parella l’any 1695. La tradició popular, sobretot des del romanticisme, els ha relacionat amb antics cabdills musulmans vençuts.\n\nActualment, a La Patum hi ha dues parelles que ballen conjuntament: els Vells, estrenats el 1866, que en van substituir uns de més antics, i els Nous, construïts l’any 1891.\n\nEls seus balls són, majoritàriament, adaptacions de melodies populars catalanes, tot i que els autors berguedans Jaume Sala i mossèn Marià Miró també van compondre balls de gegants.',
    'titol_nans-nous': 'Quatre personatges bellugadissos i estrafets',
    'text_nans-nous':
        'Foren estrenats el 1890 per tal que substituïssin els Nans Vells, que es trobaven en molt mal estat. Sortosament, però, aquests últims es van restaurar i han continuat participant a La Patum fins a l’actualitat.\n\nEls Nans Nous són dues parelles, jove l’una i vella l’altra, que ballen als acords d’una melodia airosa i juganera composta a finals del segle XIX per Joaquim Serra i Farriols, el ‘Quimserra’, que ha acabat esdevenint la més popular i divulgada de les músiques patumaires. Figura en el repertori de molts esbarts catalans amb el títol de ‘La Patum de Berga’. Abans, havien dansat diversos ritmes de moda a l’època com rigodons i americanes.',
    titol_plens: 'L’apoteosi de la festa',
    text_plens:
        'Són l’espectacle més gran de La Patum, el seu moment culminant, l’apoteosi de la festa.\n\nEstan documentats des del 1621 i es creu que deuen el seu nom al fet que van plens de foc. Són, doncs, dimonis plens de foc.\n\nFins a finals del segle XIX, els Plens evolucionaven únicament al so del Tabal, però des d’aleshores, salten amb la música genial i enardidora que va compondre el ‘Quimserra’. Quan s’apaguen els llums i comença la música, la plaça esdevé un infern que s’omple de foc amb uns mil fuets cremant alhora. En total, salten 100 Plens, cadascun amb 9 fuets. Cada Ple porta un acompanyant que el guia a través de la plaça durant el salt.\n\nVal a dir que el nombre de Plens s’ha anat multiplicant al llarg dels segles. El segle XVII hi ha documentats dos diables, abans de la Guerra Civil en saltaven 4, 8 o, com a molt i de forma excepcional, 12. Quan l’any 1916, amb motiu de La Patum extraordinària que es va celebrar per a la Coronació Canònica de la Mare de Déu de Queralt, es va fer un salt de 16 Plens, la gent va creure que hi havia massa foc a la plaça i que tot plegat suposava un perill. Més endavant en van saltar 40, nombre que s’ha anat incrementant fins al centenar que salten actualment.',
    musica_1:
        'La música és un component fonamental de la festa, sobretot des de finals del segle XIX, quan les diferents melodies es van anar incorporant a La Patum. Actualment no es concep La Patum sense les músiques interpretades per la Cobla Pirineu, la Cobla Ciutat de Berga i la Banda de l’Escola Municipal de Música de Berga.\n\nLa major part de la música de la festa és de caràcter popular; es tracta d’una música senzilla i en algun aspecte única i genial. Entre els músics patumaires cal destacar la figura de Joaquim Serra i Farriols, el ‘Quimserra’ (Berga, 1834-1906), autor de les músiques dels Turcs i Cavallets, els Plens i els Nans Nous. Ell fou qui va canviar radicalment el panorama musical de La Patum, modernitzant-lo definitivament.\n\nTambé cal fer esment de Ricard Cuadra i Camprubí (Berga 1951-1997), que va organitzar el Concert de Música Oblidada de La Patum el 1993 per recuperar antigues peces que ja no s’interpretaven. L’èxit assolit amb la reintroducció, sense proposar-s’ho, d’obres que feia dècades que s’havien arraconat, va fer que aquest concert tingués continuïtat el 1995 i el 1997, amb la recuperació de noves partitures. La seva prematura mort, el mateix 1997, va fer que s’instituís el Memorial Ricard Cuadra, que continua la tasca que ell va deixar inacabada amb un concert anual el dissabte de la Santíssima Trinitat.',
    administradors_1:
        'Tradicionalment, els encarregats de recaptar diners per pagar la festa',
    administradors_2:
        "Els Administradors i Administradores no són pròpiament una comparsa de La Patum, però sí que hi tenen un paper important.\n\nLa primera referència documental d'aquestes figures data de 1619. Tradicionalment, la seva tasca era la de recaptar fons per pagar les despeses de les festes de l'Octava de Corpus.\n\nSón quatre parelles que representen els antics Quatre Barris en que es dividia la ciutat: Feixines o Capdamunt de la Vila (Fonolls), Sant Pere (Esclafidors rebentats), Centre o carrer Major (Esclafidors embotits) i Raval (Ravenisses). Actualment, tenen un paper protocol·lari, presidint les festes des del balcó de l'Ajuntament.",
    patum_infantil_1:
        "La Patum Infantil se celebra el divendres de Corpus, tot i que l’activitat dels petits berguedans comença molt abans. Els assajos de La Patum Infantil duren dues setmanes, dies en què els infants de la ciutat aprenen els diferents balls de la celebració. És per això que se la considera una 'escola de patumaires'.\n\nEl divendres de Corpus, els nens i les nenes de Berga representen la seva pròpia Patum a la plaça de Sant Pere. Aquesta Patum consta dels mateixos elements que la representada pels adults, però d’unes dimensions més reduïdes.\n\nAl matí, La Patum Infantil comença amb una passada pels carrers de Berga i, al migdia i a la tarda, es fan les representacions de La Patum de Lluïment i La Patum completa amb totes les seves comparses: Tabal, Turcs i Cavallets, Maces i Àngels, Guites, Àliga, Nans Vells, Gegants, Nans Nous, Plens i Tirabols.",
    'Per a més informació:': '',
    patumaires_1:
        'La Patum la fan milers i milers de patumaires. Però, cada any, l’Ajuntament de Berga distingeix un seguit de persones que, per la seva implicació i col·laboració a la festa, són mereixedors d’un reconeixement especial. Se’ls atorga el Títol de Patumaire o el de Patumaire d’honor.',
    text_objecte:
        'El Patronat Municipal de La Patum és un òrgan de participació sectorial des del 29 de març de 2017, que té com a objectiu administrar, conservar i coordinar el desenvolupament de la festa de La Patum, declarada Obra Mestra del Patrimoni Oral i Immaterial de la Humanitat per la UNESCO; totes les activitats derivades i relacionades estrictament amb les comparses de la festa, les seves actuacions i els seus integrants; vetllar per la bona utilització del nom i la imatge de la celebració; així com tot allò relacionat amb la seva imatge corporativa, publicitària i de difusió i tot allò que, de forma general o específica, inclogui el nom Patum o hi faci referència. Va ser constituït el 24 d’abril de 2001 com a organisme autònom municipal.\n\nEstà format per representants dels diferents grups polítics municipals amb representació en el consistori, diversos caps de colla, que accedeixen al càrrec de manera rotativa, i representants del col·lectiu patumaire. La seva composició es renova cada dos anys, essent la corresponent al bienni 2019-21 la que es detalla a continuació: ',
    text_normativa:
        'El Patronat Municipal de La Patum, com a òrgan de participació sectorial, disposa d’una normativa pròpia que en regula el seu objectiu, composició i funcionament. ',
    text_acords:
        'El Patronat Municipal de La Patum es reuneix periòdicament durant tot l’any en sessions de caràcter ordinari o extraordinari, en les quals es tracten diversos temes en relació amb la festa.',
    text_reglaments:
        'Des del 2017, les comparses de La Patum disposen del seu reglament de règim intern, en el qual es detallen entre d’altres aspectes la composició, el funcionament i els mecanismes que disposa la ciutadana per formar-ne part o participar en els assajos o els salts. Els reglaments van ser ratificats pel Patronat Municipal de La Patum.',
    text_formularis:
        'L’Ajuntament de Berga i el Patronat Municipal de La Patum promouen la participació de la ciutadania i les entitats en diferents vessants de La Patum: en el concurs de cartells de La Patum, en el procés de selecció dels Administradors/es, en el sorteig d’accés al balcó durant les patums a la plaça o en el concurs de barres del concert jove, entre d’altres.',
    glossari_comparsa:
        'Cada una de les colles que s’encarreguen de l’actuació o el ball de les figures de la festa: el Tabal, els Turcs i Cavallets, els Àngels, les Maces, la Guita Grossa, la Guita Xica, l’Àliga, els Nans Vells, els Gegants, els Nans Nous i els Plens.',
    glossari_passacarrers:
        'Recorreguts per places i carrers de Berga en els quals intervenen algunes comparses de Patum, amb parades fixes on duen a terme els salts. Durant la setmana del Corpus, hi ha tres passacarrers amb recorreguts diferents: dimecres al migdia i vespre i dissabte al vespre.',
    glossari_salt:
        'Un dels significats és cadascuna de les actuacions o balls que duu a terme cada comparsa. També significa cadascuna de les rondes d’actuacions o balls de les comparses. En el passacarrers la ronda la formen les Maces, les Guites i els Gegants, mentre que en les patums a la plaça en la ronda hi intervenen totes les comparses, començant pels Turcs i Cavallets i finalitzant amb els Nans Nous.',
    glossari_plens:
        'En un salt de Plens la plaça de Sant Pere s’omple amb 100 Plens, els seus acompanyants i les persones que volen participar-hi. Quan les llums de la plaça s’apaguen i la música comença, els acompanyants encenen amb la seva bengala els nou fuets que duu cada Ple. S’inicia un ball girant dins de la plaça en sentit contrari a les agulles del rellotge. Per qualsevol incidència, es para la música i s’encenen els llums. El ball finalitza en el moment en el qual tots els fuets han esclatat.',
    glossari_fuet:
        'Nom amb el qual es coneix el material pirotècnic que utilitzen les comparses de foc (Maces, Guites i Plens) durant la seva actuació. Es composen d’una part que s’encén i crema deixant anar espurnes durant més d’un minut i d’una altra part que acaba esclatant en cremar-se tot el fuet.',
    glossari_tirabol:
        'Ball que posa punt i final als passacarrers i a les Patums a la plaça, en el qual els Gegants i les Guites ballen simultàniament al so del Tabal i dels músics mentre tothom dona voltes a la plaça.',
    glossari_barreja:
        'Begudes alcohòliques lligades històricament a la festa de La Patum. La barreja porta anís i moscatell i el maumau es composa de vermut negre i gasosa. Tradicionalment es beuen en bota i en porró.',
    vides: 'Per celebrar i recordar el reconeixement de La Patum com a Obra Mestra del Patrimoni Oral i Immaterial de la Humanitat, l’Ajuntament de Berga va iniciar el 2016 la gravació de les entrevistes anomenades ‘Vides de Patum’, en les quals diferents persones que han participat de manera activa en la festa expliquen de primera mà les seves vivències i records.',
};

export default TRANSLATIONS_CA;
