import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import AudioPlayer from "material-ui-audio-player";
import { PlayCircleFilled } from "@material-ui/icons";
import { Element, scroller } from "react-scroll";
import { animated, useSpring } from "@react-spring/web";
import { Circle } from "react-feather";

const songs = [
    {
        nom: "Turcs i cavallets",
        fitxer: "turcs",
    },
    {
        nom: "Les Maces",
        fitxer: "maces",
    },
    {
        nom: "L'Àliga",
        fitxer: "aliga",
    },
    {
        nom: "Els Nans Vells",
        fitxer: "nans-vells",
    },
    {
        nom: "Els Gegants",
        fitxer: "gegants",
    },
    {
        nom: "Els Nans Nous",
        fitxer: "nans-nous",
    },
    {
        nom: "Els Plens",
        fitxer: "plens",
    },
    {
        nom: "El Tirabol",
        fitxer: "tirabols",
    },
    {
        nom: "Ballet Vell Dels Gegants",
        fitxer: "ballet-vell",
    },
    {
        nom: "Vals-Jota",
        fitxer: "vals-jota",
    },
    {
        nom: "El Patumaire",
        fitxer: "el-patumaire",
    },
    {
        nom: "Babs Vells Primitius",
        fitxer: "babs-vells",
    },
    {
        nom: "Ballet dels Gegants",
        fitxer: "ballet-gegants",
    },
    {
        nom: "Pobre Pagès",
        fitxer: "pobre-pages",
    },
    {
        nom: "Ballet d'Alp",
        fitxer: "ballet-dalp",
    },
    {
        nom: "La Gaita Gallega",
        fitxer: "gaita-gallega",
    },
    {
        nom: "Ballet de Gironella",
        fitxer: "ballet-gironella",
    },
    {
        nom: "Les Nenes Maques",
        fitxer: "nenes-maques",
    },
    {
        nom: "Dansa de Fargars",
        fitxer: "dansa-falgars",
    },
    {
        nom: "L'Espunyolet",
        fitxer: "espunyolet",
    },
    {
        nom: "Ballet de Déu",
        fitxer: "ballet-deu",
    },
    {
        nom: "Corpus de 1927",
        fitxer: "corpus-1927",
    },
    {
        nom: "Ball de Gegants Num. 2",
        fitxer: "ball-gegants-2",
    },
    {
        nom: "El Gegant Vell",
        fitxer: "gegant-vell",
    },
    {
        nom: "Ella s'ho Pensa",
        fitxer: "shopensa",
    },
    {
        nom: "Marxa de La Patum",
        fitxer: "marxa",
    },
    {
        nom: "Himne de Berga",
        fitxer: "himne",
    },
];

const useStyles = makeStyles((theme) => ({
    list: {
        backgroundColor: theme.palette.background.dark,
        borderRadius: 20,
        width: "100%",
    },
    sonant: {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        backgroundColor: theme.palette.background.dark,
    },
    reproduint: {
        color: "white",
        marginTop: 15,
        flex: 1,
        display: "flex",
    },
}));

const playerStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: "none",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
            marginLeft: 0,
        },
        loopIcon: {
            color: "#3f51b5",
            "&.selected": {
                color: theme.palette.text.secondary,
            },
            "&:hover": {
                color: theme.palette.text.secondary,
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        playIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        replayIcon: {
            color: "white",
        },
        pauseIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        volumeIcon: {
            color: "white",
            "&:hover": {
                color: theme.palette.text.secondary,
            },
        },
        volumeSlider: {
            color: theme.palette.background.default,
        },
        progressTime: {
            color: "white",
        },
        mainSlider: {
            color: "white",
            "& .MuiSlider-rail": {
                color: "white",
            },
            "& .MuiSlider-track": {
                color: "white",
            },
            "& .MuiSlider-thumb": {
                color: "white",
            },
        },
    };
});

const MusicaScreen = () => {
    const classes = useStyles();
    const [song, setSong] = useState();
    const styles = useSpring({
        loop: song?.nom ? true : false,
        from: { scale: 0.5 },
        to: { scale: 0.7 },
    });

    return (
        <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Element name="player">
                <AudioPlayer
                    elevation={1}
                    width="100%"
                    variation="default"
                    spacing={3}
                    order="standart"
                    preload="auto"
                    autoplay={true}
                    src={song?.fitxer}
                    useStyles={playerStyles}
                />
                <Box p={3} py={2} className={classes.sonant}>
                    <Typography variant="body1" className={classes.reproduint}>
                        <animated.div style={{ marginRight: 10, ...styles }}>
                            <Circle style={{ fill: "white" }} />
                        </animated.div>{" "}
                        {song?.nom}
                    </Typography>
                </Box>
                <Box py={3} />
                <List component="nav" className={classes.list}>
                    {songs.map((item) => {
                        return (
                            <ListItem
                                button
                                onClick={() => {
                                    setSong({
                                        nom: item.nom,
                                        fitxer: `${process.env.PUBLIC_URL}/sons/${item.fitxer}.mp3`,
                                    });
                                    scroller.scrollTo("player", {
                                        duration: 800,
                                        delay: 0,
                                        smooth: "easeInOutQuart",
                                        offset: -150,
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <PlayCircleFilled
                                        style={{ fill: "white" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    classes={classes.listItem}
                                    color="white"
                                    primary={
                                        <Typography
                                            type="body1"
                                            style={{ color: "#FFFFFF" }}
                                        >
                                            {item.nom}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Element>
        </Box>
    );
};

export default MusicaScreen;
