import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import routes from "./routes";
import { useRoutes } from "react-router-dom";

function App() {
    const routing = useRoutes(routes());

    return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
}

export default App;
