import React, { useRef } from "react";
import { Box, Container, makeStyles, useMediaQuery } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";
import Logo from "../../../components/Logo";
import CompteEnrere from "../../../components/CompteEnrere";
import useMousePosition from "../../../utils/useMousePosition";
import { useSpring, animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import { ReactComponent as Patum } from "../../../theme/icons/logo.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    background: {
        marginTop: -100,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: theme.palette.background.default,
        backgroundImage: `url("${process.env.PUBLIC_URL}/images/web-patum-portada.jpg")`,
        minHeight: "-webkit-fill-available",
    },
    main: {
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        width: "50%",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    logo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
        height: "60vh",
    },
    text: {
        textAlign: "center",
    },
}));

const { clientWidth: width } = document.documentElement;

const overlay = {
    background: "#d9594e",
    position: "absolute",
    width: width,
    top: 0,
    overflow: "auto",
    zIndex: 0,
};

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 1000;

const DashboardView = ({ className, onMobileNavOpen, ...rest }) => {
    const classes = useStyles();
    const { clientX, clientY } = useMousePosition();
    const [{ opacity, scale }, set] = useSpring(() => ({
        opacity: calcX(clientX, clientY),
        scale: 1,
    }));
    const domTarget = useRef(null);
    const matches = useMediaQuery("(min-width:960px)");

    useGesture(
        {
            onMove: ({ xy: [clientX, clientY] }) =>
                set.start({
                    opacity: Math.abs(calcX(clientX, clientY)) + 0.3,
                    scale: 1 - Math.abs(calcX(clientX, clientY)) / 8 - 0.9 + 1,
                }),
            onTouchStart: ({ xy: [clientX, clientY] }) =>
                set.start({
                    opacity: Math.abs(calcX(clientX, clientY)) + 0.3,
                    scale: 1 - Math.abs(calcX(clientX, clientY)) / 8 - 0.9 + 1,
                }),
        },
        { domTarget, eventOptions: { passive: false } }
    );

    return (
        <Box
            height={matches ? "100vh" : ""}
            className={classes.background}
            onMouseMove={() => set.start(clientX)}
            ref={domTarget}
        >
            <ScrollAnimation
                animateIn="fadeIn"
                delay={300}
                style={{ transition: "0.5s" }}
            >
                <Container className={classes.root}>
                    <animated.div className={classes.main}>
                        <animated.div
                            className={classes.logo}
                            style={{ scale }}
                        >
                            <Logo width={matches ? 300 : 250} icon={Patum} />
                        </animated.div>
                        <CompteEnrere />
                    </animated.div>
                </Container>
                <animated.div
                    style={{
                        height: matches ? "100vh" : "90vh",
                        ...overlay,
                        opacity,
                    }}
                ></animated.div>
            </ScrollAnimation>
        </Box>
    );
};

export default DashboardView;
